<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('ditfConfig.stall_pavilion_restaurant') + ' ' + $t('ditfConfig.category') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col sm="4">
              <b-form-group
                label-for="year"
              >
              <template v-slot:label>
                {{ $t('globalTrans.year') }}
              </template>
              <b-form-select
                plain
                v-model="search.year"
                :options="yearList"
                id="year"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="4">
              <b-form-group
                label-for="fair_id"
              >
              <template v-slot:label>
                {{ $t('ditfConfig.fair_name') }}
              </template>
              <b-form-select
                plain
                v-model="search.fair_id"
                :options="fairTitleList"
                id="fair_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="4">
              <b-form-group
                label-for="circular_type"
              >
              <template v-slot:label>
                {{$t('ditfConfig.circular') + ' ' + $t('ditfConfig.type')}}
              </template>
              <b-form-select
                plain
                v-model="search.circular_type"
                :options="circularTypeList"
                id="circular_type"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="4">
              <ValidationProvider name="Stall Category" vid="stall_category" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="stall_category"
              >
                <template v-slot:label>
                  {{ $t('tradeFairConfig.stall_category') }}
                </template>
                <b-form-select
                  plain
                  id="stall_category"
                  :options="stallCategoryList"
                  v-model="search.stall_category"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
            </b-col>
          <b-col sm="4">
              <b-form-group
                label-for="stall_type"
              >
              <template v-slot:label>
                {{$t('ditfAppTradeFairManages.stall_type')}}
              </template>
              <b-form-select
                plain
                :disabled="search.circular_type == 1 ? false : true"
                v-model="search.stall_type"
                :options="stallTypeList"
                id="stall_type"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="4" class="mt-3">
            <b-button size="sm" variant="primary" class="" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('ditfConfig.stall_pavilion_restaurant') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="text-center" v-if="circularInfo"><span v-if="new Date(circularInfo.deadline) >= currentDate" class="blink_me text-center">{{ $t('ditfAppTradeFairManages.assign_stall_restriction') }}</span></b-col>
            <b-col md="12" class="table-responsive mt-2">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(processing_fee)="data">
                    {{ $n(data.item.processing_fee, { minimumFractionDigits: 2 }) }}
                  </template>
                  <template v-slot:cell(floor_price)="data">
                    {{ $n(data.item.floor_price, { minimumFractionDigits: 2 }) }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-if="data.item?.stall_category?.circular_type === 1" :disabled="new Date(circularInfo.deadline) >= currentDate ? true : false" variant="iq-bg-success mr-1" size="sm" @click="sorting(data.item)" class="action-btn edit"><span><i class="ri-eye-fill"></i></span></b-button>
                    <b-button  v-b-modal.modal-5 v-else :disabled="new Date(circularInfo.deadline) >= currentDate ? true : false" variant="iq-bg-success mr-1" size="sm" @click="detailsCategoryApplicant(data.item)" class="action-btn status"><i class="ri-eye-fill"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-5" size="lg" :title="$t('ditfAppTradeFairManages.applicant_stall')" hide-footer ok-only ok-variant="danger">
      <template #modal-title class="flex-grow-1">
        {{ $t('ditfAppTradeFairManages.applicant_stall') + ' ' + $t('globalTrans.details') }}
        <b-button variant="success" @click="pdfExport(item)" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <ApplyCategory :itemId="itemId" :item="item" ref="details"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { assignStallCategoryList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ApplyCategory from './ApplyCategory'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ApplyCategory
  },
  data () {
    return {
      valid: '',
      sortBy: '',
      fairTitleList: [],
      circularInfo: [],
      currentDate: '',
      isActive: false,
      itemId: 0,
      item: '',
      search: {
        year: 0,
        fair_id: 0,
        circular_type: 1,
        stall_category: 0,
        stall_type: 0,
        limit: 20
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '9%' } },
        { labels: 'ditfAppTradeFairManages.stall_type', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '9%' } },
        { labels: 'ditfAppTradeFairManages.cat_name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '9%' } },
        { labels: 'ditfConfig.size', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
        { labels: 'ditfConfig.price_type', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
        { labels: 'ditfConfig.app_porcess_fee', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
        { labels: 'ditfConfig.fare_floor', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '15%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 8 }
      ],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    // const date = new Date()
    // const dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
    this.currentDate = new Date()
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    this.loadData()
  },
  watch: {
    'search.year': function (newVal) {
      this.fairTitleList = this.getFairTitle(newVal)
        this.search.fair_id = this.fairTitleList[0].value
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    stallTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'stall_type_bn' },
          { key: 'cat_name_bn' },
          { key: 'size_bn' },
          { key: 'price_type_bn' },
          { key: 'processing_fee' },
          { key: 'floor_price' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'stall_type_en' },
          { key: 'cat_name_en' },
          { key: 'size_en' },
          { key: 'price_type_en' },
          { key: 'processing_fee' },
          { key: 'floor_price' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    sorting (item) {
      this.$router.push({ path: '/trade-fair-service/ditf/trade-fair-management/category-wise-stall-list', query: { stall_cat_det_id: item.id } })
    },
    detailsCategoryApplicant (item) {
      this.item = item
      this.itemId = item.id
    },
    getFairTitle (year) {
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === year).map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage }, { stall_cat_id: this.$route.query.stall_cat_id })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, assignStallCategoryList, params)
      if (result.success) {
        this.circularInfo = result.circular_info
        const listData = result.data.data.map((item, index) => {
          const stallTypeObj = this.$store.state.TradeFairService.ditfCommonObj.stallTypeList.find(el => el.value === parseInt(item.stall_type))
          const stallTypeData = {
            stall_type_bn: stallTypeObj !== undefined ? stallTypeObj.text_bn : '',
            stall_type_en: stallTypeObj !== undefined ? stallTypeObj.text_en : ''
          }
          const priceTypeObj = this.$store.state.TradeFairService.ditfCommonObj.priceTypeList.find(el => el.value === parseInt(item.price_type))
          const priceTypeData = {
            price_type_bn: priceTypeObj !== undefined ? priceTypeObj.text_bn : '',
            price_type_en: priceTypeObj !== undefined ? priceTypeObj.text_en : ''
          }
          return Object.assign({}, item, { serial: index }, stallTypeData, priceTypeData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    pdfExport (item) {
      this.$refs.details.pdfExport(item)
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
<style scoped>
  .modal-title {
    flex-grow: 1 !important;
  }
  .blink_me {
    animation: blinker 2.5s linear infinite;
    color: red;
  }
  @keyframes blinker {
    50% {
      opacity: .09;
      /* background-color: green; */
    }
  }
</style>
